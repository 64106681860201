<template>
  <el-container class="eyetelligence-body">
    <el-header height="61" class="eyetelligence-header">
      <el-menu
        :router="true"
        mode="horizontal"
        class="eyetelligence-menu">
        <el-menu-item>
          <span style="font-size: 20px" slot="title"><img class="logo" src="@/assets/logo.jpg" />Eyetelligence Cloud</span>
        </el-menu-item>
        <el-menu-item class="menu-item-last">
          <el-button @click="signOut">Logout</el-button>
        </el-menu-item>
      </el-menu>
    </el-header>
    <el-container class="eyetelligence-container">
      <el-aside width="200px" class="eyetelligence-aside">
        <el-menu
          :router="true"
          class="eyetelligence-menu">
          <el-menu-item route="/work/customers" index="/work/customers">
            <span slot="title">Customers</span>
          </el-menu-item>
          <el-menu-item route="/work/app" index="/work/app">
            <span slot="title">App Releases</span>
          </el-menu-item>
          <el-menu-item route="/work/model" index="/work/model">
            <span slot="title">Model Releases</span>
          </el-menu-item>
          <el-menu-item route="/work/users" index="/work/users">
            <span slot="title">Users</span>
          </el-menu-item>
          <el-menu-item route="/work/logs" index="/work/logs">
            <span slot="title">Logs</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-container class="eyetelligence-main">
        <router-view></router-view>
      </el-container>
    </el-container>
  </el-container>

</template>

<style lang="less">
.eyetelligence-body {
  height: 100%;
  min-height: 100vh;
}
.el-divider--horizontal {
  margin: 0 0 25px;
}
.btn-group .el-button{
  float: right;
}
.btn-group > .el-button {
  margin-right: 20px
}
.eyetelligence-header {
  border-bottom: solid 1px #e6e6e6;
  box-sizing: border-box;
  height: 61px;
  .eyetelligence-menu {
    border: 0;
  }
  .menu-item-last {
    float: right;
  }
  .logo {
    width: 150px
  }
}
.eyetelligence-menu {
  .el-menu-item {
    border-bottom: 1px solid #e6e6e6
  }
}
.eyetelligence-container {
  height: 100%;
}
.el-aside {
  color: #333;
  height: auto;
  border-right: solid 1px #e6e6e6;
  .eyetelligence-menu {
    border: 0;
  }
}
</style>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
    }
  },
  mounted () {
  },
  computed: mapState({
    user: state => state.user.user
  }),
  methods: {
    signOut () {
      this.$confirm('Confirm logout?')
        .then(_ => {
          localStorage.clear('token')
          localStorage.clear('tokenExpiredAt')
          global.defaultStore.commit('SIGNOUT')
          this.$router.push({ path: '/' })
        })
        .catch(_ => {})
    }
  }
}
</script>
